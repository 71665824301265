import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import { accountTypeDisplay } from "./shared/Utilities";

import { renderLabel } from "./shared/Utilities";

export default function Signature() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const [bankName, setBankName] = useState();
  const [accountType, setAccountType] = useState();

  const [signatureImage, setSignatureImage] = useState();
  const [signatureTyped, setSignatureTyped] = useState();
  const [signatureLanguage, setSignatureLanguage] = useState();

  const [newSignatureImage, setNewSignatureImage] = useState();

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/signature");

    setSignatureImage(response.result?.fuel_program_application.signature_image)
    setSignatureTyped(response.result?.fuel_program_application.signature_typed || '')

    setSignatureLanguage(response.result?.signature_language)

    setBankName(response.result?.bank_account.bank_name);
    setAccountType(response.result?.bank_account.account_type);

    setBusy(false);
    
    setTimeout(() => {
      window.initialize_signature_capture();
      setTimeout(() => {
        window.setSignatureCallback((signatureImg) => {
          setNewSignatureImage(signatureImg);
        });
      }, 500);
    }, 250)
  }

  const save = async () => {
    setSaveBusy(true);

    let signature = signatureImage;
    if (newSignatureImage) {
      signature = newSignatureImage;
    }

    const request = {
      fuel_program_application: {
        signature_image: signature || '',
        signature_typed: signatureTyped || ''
      }
    };

    const response = await client.postData("/user-portal/fpa/signature", request);

    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setSaveBusy(false);
  }

  const clearCanvas = () => {
    setSignatureImage(null);
    setNewSignatureImage(null);
    window.clear_signature_capture();
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Signature</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />

          <div className="row mb-3">
            <div className="col-md-3">
              Bank Name: <strong>{bankName}</strong>
            </div>
            <div className="col-md-3">
              Account Type: <strong>{accountTypeDisplay(accountType)}</strong>
            </div>
            <div className="col-md-6">
              &nbsp;
            </div>
          </div>


          <div className="row mb-3">
            <div className="col-md-12">
              {signatureLanguage}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="md-form">
                <input type="hidden" name="fuel_program_application[signature_image]" id="fuel_program_application_signature_image" />
                <div id="signature-pad" className="signature-pad">
                  <div className="signature-pad--body">
                    <canvas className={!!signatureImage ? 'force-hide' : ''}></canvas>
                    {!!signatureImage && <img src={signatureImage} />}
                  </div>
                  <div className="signature-pad--footer">
                    <div className="description">Sign above</div>
                    <button onClick={clearCanvas}>Clear</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input id='signature-typed' value={signatureTyped} onChange={(e) => setSignatureTyped(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Alternatively: Type your name to sign', signatureTyped, 'signature-typed')}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Next
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
