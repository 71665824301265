import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import { accountTypeDisplay } from "./shared/Utilities";

import { renderLabel } from "./shared/Utilities";

export default function CardPin() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  const [cardPin, setCardPin] = useState();

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/card-pin");

    // Don't do this, we don't want to have the pin on the data-stream
    // setCardPin(response.result?.card_pin);

    setBusy(false);
  }

  const save = async () => {
    setSaveBusy(true);

    const request = {
      fuel_program_application: {
        card_pin: cardPin
      }
    };

    const response = await client.postData("/user-portal/fpa/card-pin", request);

    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setSaveBusy(false);
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Card Pin</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />

          <p>
            Please specify a 4-digit secure pin to use when fueling with your card.
          </p>

          <div className="row mb-3">
            <div className="col-md-12">
              <div className="md-form">
                <input id='card-pin' value={cardPin} onChange={(e) => setCardPin(e.target.value)} type="password" className="form-control" aria-disabled="false" max={4} maxLength={4} />
                {renderLabel('Choose a pin number for your card', cardPin, 'card-pin')}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Next
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
