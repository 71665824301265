import React, { useEffect, useState, useCallback } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import { renderSimpleLabel } from "./shared/Utilities";
import { accountTypeDisplay } from "./shared/Utilities";
import { usePlaidLink } from 'react-plaid-link';

// https://github.com/plaid/react-plaid-link
// https://github.com/plaid/react-plaid-link/blob/master/examples/simple.tsx

const PLAID_IDENTITY_VERIFICATION_STATUS_PASS_SESSION = 'IDENTITY_VERIFICATION_PASS_SESSION';
const PLAID_IDENTITY_VERIFICATION_STATUS_FAIL_SESSION = 'IDENTITY_VERIFICATION_FAIL_SESSION';

export default function IdentityVerification() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);
  
  const [linkToken, setLinkToken] = useState(null);
  const [identityVerificationStatus, setIdentityVerificationStatus] = useState(null);
  const [identityVerificationStatusAt, setIdentityVerificationStatusAt] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/identity-verification");

    setIdentityVerificationStatus(response.result?.fuel_program_application.plaid_verification_status);
    setIdentityVerificationStatusAt(response.result?.fuel_program_application.plaid_verification_status_at);
    setNextUrl(response.result?.next_url)

    setBusy(false);
  }
  
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (publicToken, metadata) => {
      // Do nothing
    },
    onEvent: async (event, metadata) => {
      if (event == PLAID_IDENTITY_VERIFICATION_STATUS_PASS_SESSION || event == PLAID_IDENTITY_VERIFICATION_STATUS_FAIL_SESSION) {
        setBusy(true);

        setIdentityVerificationStatus(event);
        const request = {
          fuel_program_application: {
            plaid_verification_status: event,
            plaid_verification_metadata: JSON.stringify(metadata)
          }
        };
        const response = await client.postData('/user-portal/fpa/identity-verification', request);
        if (response.status === client.Error) {
          setErrorMessages(response.error_messages);
        }

        setBusy(false);
      }
    },
    onExit: (event, metadata) => {
      // Do nothing
    }
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  const handleStartInstantVerification = async () => {
    setSaveBusy(true);
    const response = await client.postData("/user-portal/fpa/create-identity-verification-link-token");
    setLinkToken(response.result?.link_token);
    setSaveBusy(false);
  }

  const next = () => {
    window.location.href = nextUrl;
  }

  const renderPlaidInstantVerificationSuccess = () => {
    return (
    <>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h1>Now We're Talking!</h1>
          <img src="/assets/balloons.gif" height="120px" />
          <h3>Identity verification was successful</h3>
          <p>Now that your identity has been verified, you can continue your application.</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <button onClick={next} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
            Next
            <div className="Ripple"></div>
          </button>
        </div>
      </div>
    </>
    );
  }

  const renderPlaidInstantVerificationFailure = () => {
    return (
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h1>Oh no!</h1>
          <h3>Identity verification could not be completed.</h3>
          <p>
            Please contact customer service at 1-800-275-5089 to assist with the approval process.
          </p>
          <p>
            Customer Service Hours
          </p>
          <div>
            <table style={{margin: "0 auto"}}>
              <tr>
                <td>Mon</td>
                <td>08:00 am – 05:00 pm</td>
                <td></td>
              </tr>
              <tr>
                <td>Tue</td>
                <td>08:00 am – 05:00 pm</td>
                <td></td>
              </tr>
              <tr>
                <td>Wed</td>
                <td>08:00 am – 05:00 pm</td>
                <td></td>
              </tr>
              <tr>
                <td>Thu</td>
                <td>08:00 am – 05:00 pm</td>
                <td></td>
              </tr>
              <tr>
                <td>Fri</td>
                <td>08:00 am – 05:00 pm</td>
                <td></td>
              </tr>
              <tr>
                <td>Sat</td>
                <td>08:00 am – 04:00 pm</td>
                <td></td>
              </tr>
              <tr>
                <td>Sun</td>
                <td>08:00 am – 04:00 pm</td>
                <td></td>
              </tr>
            </table>
          </div>

          <div className='row mt-4'>
            <div className='col-md-12 text-center'>
              <button onClick={handleStartInstantVerification} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Verify My Identity
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <div>
          <ErrorMessages errorMessages={errorMessages} />

          {identityVerificationStatus ? (
            <>
              {identityVerificationStatus == PLAID_IDENTITY_VERIFICATION_STATUS_FAIL_SESSION ? renderPlaidInstantVerificationFailure() : renderPlaidInstantVerificationSuccess()}
            </>
          ) : (
            <div className='row'>
              <div className='col-md-12 text-center'>
                <h1>Third Party Identity Verification</h1>
                <p>Due to increased security requirements from our financial institutions, we will need to verify<br/> your identity using information such as: phone number, home address, and a photo identity card.</p>

                <div className='row'>
                  <div className='col-md-12 text-center'>
                    <button onClick={handleStartInstantVerification} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                      Verify My Identity
                      <div className="Ripple"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      </div>
    </>
  </div>
  );
}
