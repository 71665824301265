import React, { useEffect, useState } from "react";
import client from "./client/Client";
import LoadingIndicator from "./shared/LoadingIndicator";
import ApplicationProgress from "./shared/ApplicationProgress";
import ErrorMessages from "./shared/ErrorMessages";
import SsnInput from "./shared/SsnInput";

import { renderLabel } from "./shared/Utilities";
import { states } from "./shared/States";

export default function Personal() {
  const [busy, setBusy] = useState(true);
  const [saveBusy, setSaveBusy] = useState(false);

  const [errorMessages, setErrorMessages] = useState([]);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await client.getData("/user-portal/fpa/personal");
    
    setFirstName(response.result?.fuel_program_application.first_name || '')
    setLastName(response.result?.fuel_program_application.last_name || '')
    setEmail(response.result?.fuel_program_application.email || '')
    setPhone(response.result?.fuel_program_application.phone || '')
    setAddress1(response.result?.fuel_program_application.address1 || '')
    setAddress2(response.result?.fuel_program_application.address2 || '')
    setCity(response.result?.fuel_program_application.city || '')
    setState(response.result?.fuel_program_application.state || '')
    setZipcode(response.result?.fuel_program_application.zipcode || '')

    setBusy(false);
  }

  const save = async () => {
    setSaveBusy(true);

    const request = {
      fuel_program_application: {
        first_name: firstName || null, // Null is at least defined and will include the empty attribute
        last_name: lastName || null,
        email: email || null,
        phone: phone || null,
        address1: address1 || null,
        address2: address2 || null,
        city: city || null,
        state: state || null,
        zipcode: zipcode || null
      }
    };
    const response = await client.postData("/user-portal/fpa/personal", request);
    if (response.status === client.Error) {
      setErrorMessages(response.error_messages);
    }

    setSaveBusy(false);
  }

  return (
  <div className='container'>
    <>
      <ApplicationProgress />
      <h4 className="mb-2 mt-2">Personal</h4>
      <div className="container-fluid section mb-5 border p-4">
        {busy ? ( 
          <div className="row">
            <div className="col-md-12 text-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
        <>
          <ErrorMessages errorMessages={errorMessages} />
          
          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input id="first-name" value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('First Name', firstName, 'first-name')}
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input id='last-name' value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Last Name', lastName, 'last-name')}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input id='phone' value={phone} onChange={(e) => setPhone(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Phone', phone, 'phone')}
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input id='email' value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" aria-disabled="false" readOnly />
                {renderLabel('Email', email, 'email')}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input id='address1' value={address1} onChange={(e) => setAddress1(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Address', address1, 'address1')}
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <input id='address2' value={address2} onChange={(e) => setAddress2(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Address Line 2', address2, 'address2')}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="md-form">
                <input id='city' value={city} onChange={(e) => setCity(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('City', city, 'city')}
              </div>
            </div>
            <div className="col-md-6">
              <div className="md-form">
                <select value={state} onChange={(e) => setState(e.target.value)} className="browser-default custom-select custom-select-sm">
                  {states.map((state, index) => (
                    <option key={`state_${index}`} value={state[1]}>{state[0]}</option>
                  ))}
                </select>
                <label className="active">
                  State
                </label>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="md-form">
                <input id='zip' value={zipcode} onChange={(e) => setZipcode(e.target.value)} type="text" className="form-control" aria-disabled="false" />
                {renderLabel('Zip', zipcode, 'zip')}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <button onClick={save} type="button" className="btn Ripple-parent btn-success" disabled={saveBusy}>
                Next
                <div className="Ripple"></div>
              </button>
            </div>
          </div>
        </>
      )}
      </div>
    </>
  </div>
  );
}
